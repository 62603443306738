<template>
    <div class="content">
        <!-- 제출 서류 -->
        <div class="submit_document">
            <h2>제출서류</h2>
            <div class="business_type l_list_style">
                <dl>
                    <dt><h3>법인사업자</h3></dt>
                    <dd>인감 날인된계약서 2부</dd>
                    <dd>법인인감증명서 1부</dd>
                    <dd>사업자등록증 사본 1부</dd>
                    <dd>법인 통장사본 1부</dd>
                </dl>
                <dl>
                    <dt><h3>개인사업자</h3></dt>
                    <dd>날인된계약서 2부</dd>
                    <dd>사업자등록증 사본 1부</dd>
                    <dd>신분증 사본(앞/뒷면)</dd>
                    <dd>통장사본 1부</dd>
                </dl>
                <dl>
                    <dt><h3>개인판매자</h3></dt>
                    <dd>날인된계약서 2부</dd>
                    <dd>신분증 사본(앞/뒷면)</dd>
                    <dd>통장사본 1부</dd>
                </dl>
            </div>
            <!--
            <div class="impress">
                <dl>
                    <dt><h3>계약서 날인안내</h3><button @click="openPopup('way')" class="c_btn_common c_btn_primary-fill">계약서 날인방법</button></dt>
                    <dd class="l_exclamation">유의업종 또는 리스크 업종의 경우 담당자가 추가 증빙을 요청 드릴 수 있습니다. <a @click="openPopup('standard')" class="link">업종 기준 보기</a></dd>
                </dl>
            </div>
            -->
        </div>

        <!-- 제출안내 -->
        <div class="two_grid">
            <h2>제출안내</h2>
            <div class="l_list_style">
                <dl>
                    <dt><h3>이메일 접수</h3></dt>
                    <dd>구비서류(계약서 포함)를 한 장씩 스캔 또는 스마트폰 촬영 후 아래 주소로 제출해 주시기 바랍니다.</dd>
                    <dd>이메일 <a href="mailto:ton@toncompany.co.kr" class="link">ton@toncompany.co.kr</a></dd>
                </dl>
                <dl>
                    <dt><h3>우편 접수</h3></dt>
                    <dd>구비서류(계약서 포함)를 프린트 후 작성해서 아래 주소로 발송해 주시기 바랍니다.</dd>
                    <dd>(06174) 서울특별시 강남구 영동대로82길 9, G1(대치동)</dd>
                </dl>
            </div>
        </div>

        <!-- 업종심사 -->
        <!-- <div class="judge">
            <h2>업종심사</h2>
            <p>
                계약서류 검토와 함께 간단한 업종심사가 이어집니다.<br />
                유의업종 및 리스크업종의 경우 결제수수료가 인상되며, 불가업종의 경우 결제가 취소될 수 있습니다.
            </p>
            <button @click="openPopup('standard')" class="c_btn_common c_btn_primary-fill">유의 및 불가 업종 보기</button>
        </div> -->

        <!-- 보증보험 -->
        <div class="insurance">
            <h2>보증보험</h2>
            <p>결제규모와 업종에 따라 보증보험 신청을 요청 드릴 수 있습니다.<br />자세한 사항은 고객센터(<a href="tel:02-567-7705" style="user-select:auto">02-567-7705</a>)로 문의주시기 바랍니다.</p>
            <!-- <button type="button" class="c_btn_common c_btn_primary-fill">보증보험 안내 보기</button> -->
        </div>
    </div>

    <Guide02Popup
        :whichPopup="whichPopup"
        @closePopup="closePopup"
    />
</template>

<script>
import Guide02Popup from '@/components/service/Guide02Popup'
export default {
    components: {
        Guide02Popup
    },
    data(){
        return {
            whichPopup : '',
        }
    },
    methods:{
        openPopup(which){
            this.whichPopup = which;
        },
        closePopup(){
            this.whichPopup = '';
        }
    }
}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_service.css"></style>