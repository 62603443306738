<template>
    <div class="service_guide">
        <ServiceSubTab :navList="navList" />
        
        <!-- guide01, guide02, guide03 -->
        <router-view></router-view>
    </div>
</template>

<script>
import ServiceSubTab from '@/components/service/ServiceSubTab.vue'

export default {
    components: {
        ServiceSubTab,
    },
    data(){
        return{
            navList : [
                {
                    to : '/service/guide/guide01',
                    content : '이용절차'
                },
                {
                    to : '/service/guide/guide02',
                    content : '구비서류<span class="m_hidden">/심사</span>'
                },
                {
                    to : '/service/guide/guide03',
                    content : '정산안내'
                },

            ]
        }
    }
}
</script>