<template>
    <div class="content">
        <!-- 정산대상 -->
        <div>
            <h2>정산대상</h2>
            <p>
                티온페이로 제출하신 가입대상 법인, 개인계좌로 정산되며 정산 PG사는 서비스 형태에 따라 변동될 수 있습니다.
            </p>
        </div>

        <!-- 정산주기 -->
        <div class="two_grid calculate_cycle">
            <h2>정산주기</h2>
            <div>
                <dl>
                    <dt><h3>기본정산주기</h3></dt>
                    <dd>
                        <span>D+5일</span>
                        <span>
                            첫정산 이후에는 결제일 이후 5영업일째 되는 평일에, 등록된 계좌로
                            결제수수료를 제외한 금액이 자동으로 정산 입금됩니다.
                            (별도신청 불필요)
                        </span>
                    </dd>
                </dl>
                <dl>
                    <dt><h3>부가서비스</h3></dt>
                    <dd>
                        <span>D+1일(익일정산)</span>
                        <span>결제일 다음 영업일에 정산 입금</span>
                    </dd>
                    <dd>
                        <span>D+3일(3일정산)</span>
                        <span>결제일 3영업일 후 결제 대금 입금</span>
                    </dd>
                </dl>
            </div>
            <div class="wrapper">
                <ul class="cycle_process">
                    <li>
                        <p>결제일</p>
                    </li>
                    <li>
                        <p>D+1일<br />익일정산</p>
                    </li>
                    <li>
                        <p>D+2일</p>
                    </li>
                    <li>
                        <p>휴무일</p>
                    </li>
                    <li>
                        <p>휴무일</p>
                    </li>
                    <li>
                        <p>D+3일<br />3일정산</p>
                    </li>
                    <li>
                        <p>D+4일</p>
                    </li>
                    <li>
                        <p>D+5일<br />정산</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/layout_service.css"></style>