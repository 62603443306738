export const businessNumFormat = {
    methods: {
        businessNumFormat(num) {
        if (!num) {
          return '';
        }
        return num.replace(/[^0-9]/g, "") //숫자만
          .replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      }
    },
  }